import './app/globals';
/*import {init} from 'shareon';
 import 'shareon/css';*/
import {
    adjustGoTopOpacity,
    buildToc,
    collapseHeader,
    fixTables,
    header,
    root,
    scrollTopNav,
    setCurrentLinks,
    setupAnalyticsGA,
    setupExchangePage,
    setupFootnotes,
    setupFormLinks,
    setupForms,
    setupHighlighting,
    setupModalTriggers,
    setupOnlineDecoration,
    setupScrollEvents,
    setupSearchButton,
    setupTabs
} from './app/_inc/helpers';

window.loadTag             = window.loadTag ?? (tag => tag);
window.CookieConsentTools  = window.CookieConsentTools ?? {
    get: () => {
    }
};
window.cookieConsentLoaded = () => {
    console.log('coookie.tools loaded', window.CookieConsentTools.get());
    _q('a#cookie-options')?.addEventListener('click', () => window.CookieConsentTools.ui.view('mini-widget'));
};
window.getCookie           = (name) => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};
window.setCookie           = (name, value) => {
    document.cookie = name + '=' + value + '; Path=/;';
};
window.deleteCookie        = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
window.loadAnalytics       = async () => {

    window.cookieOptionsCookieName = window.cookieOptionsCookieName ?? 'cookie_options';
    window.tracking                = window.tracking ?? {
        gtm_id: null,
        ga_id:  null
    };

    if (typeof window.tracking.gtm_id === 'string' && window.tracking.gtm_id !== '') {

        let cookieOptions;

        try {
            const cookie  = window.getCookie(cookieOptionsCookieName);
            cookieOptions = cookie ? JSON.parse(decodeURIComponent(cookie)) : {};
        } catch (error) {
            console.log('Cookie decode error', getCookie(cookieOptionsCookieName));
            window.deleteCookie(cookieOptionsCookieName);
        }

        if (typeof cookieOptions === 'object' && cookieOptions.marketing === true) {
            // GTM tag is loaded via HTML in resources/views/pages/templates/global/tracking.blade.php#L1
            console.log('GTM tag loaded elsewhere');
        } else {
            const fp          = await FingerprintJS.load();
            const {visitorId} = await fp.get();
            await setupAnalyticsGA(window.tracking.ga_id, visitorId, cookieOptions);
        }

    } else {
        console.log('No Analytics and/or Tag Manager ID found');
    }
};
window.modal               = () => {
    const element = () => document.querySelector('#modal');

    const close = () => element().classList.add('hidden');

    const content = (html) => {
        element().querySelector('.body').innerHTML = html;
    };

    const open = (html) => {
        if (html) {
            content(html);
        }

        element().classList.remove('hidden');
    };

    return {
        close,
        open,
        content
    };
};
window.toggleMenu          = (el, target) => {
    const menuShown     = parseInt(localStorage.getItem('menuShown') ?? 0);
    const _toggle       = (el, selector, className) => _qa(el, selector).forEach(t => t.classList.toggle(className));
    const _toggleHidden = (el, selector) => _toggle(el, selector, 'hidden');

    _toggle(document, 'header', 'open');
    _toggle(document, 'body', 'overflow-hidden');

    _toggleHidden(el, '.show-menu');
    _toggleHidden(el, '.close-menu');

    _toggleHidden(target, '.mobile-menu-visible');
    _toggleHidden(target, '.mobile-menu.categories.parent');

    // Scroll links menu horizontally to the right and back, max three times
    if (header.classList.contains('open') && menuShown < 3) {
        // Show last link (it is outside the viewport)
        window.setTimeout(() => _q('.links.help a:last-child').scrollIntoViewSmoothly(), 400);
        // Show first link again
        window.setTimeout(() => _q('.links.help a:first-child').scrollIntoViewSmoothly(), 1000);
        localStorage.setItem('menuShown', menuShown + 1 + '');
    }
};
window.toggleSubmenu       = (event, el) => {
    event.preventDefault();
    _qa('.show-submenu').forEach(e => e !== el.parentElement && e.classList.remove('show-submenu'));
    el.parentElement.classList.toggle('show-submenu');
};
window.delayTimeout        = window.delayTimeout ?? 10000;
window.schedule            = {
    now:      [
        () => {
            if (root && header) {
                root.style.setProperty('--header-height', header.clientHeight + 'px');
            }
        },
        buildToc
    ],
    delayed:  [
        loadAnalytics,
        adjustGoTopOpacity,
        fixTables,
        setCurrentLinks,
        setupTabs,
        setupSearchButton,
        setupForms,
        setupModalTriggers,
        setupExchangePage,
        setupHighlighting,
        setupFormLinks,
        setupFootnotes,
        setupOnlineDecoration,
        scrollTopNav
    ],
    timeouts: {
        100:                            () => {
            if (location.hash) {
                const fragments = location.hash.replace('#', '').split(':');
                const route     = fragments.shift();
                switch (route) {
                    case 'tab':
                        const tab = fragments.shift();
                        if (window['activateTab']) {
                            activateTab({
                                            tab:      '#' + tab,
                                            scrollTo: true
                                        });
                        }
                }
            }
        },
        200:                            () => {
            collapseHeader({endY: window.scrollY});
        },
        2000:                           () => {
            setupScrollEvents();
            window.addEventListener('resize', e => buildToc(e));
            window.addEventListener('locationchange', () => setCurrentLinks(location));
            window.addEventListener('scroll', adjustGoTopOpacity);
        },
        [window.delayTimeout ?? 10000]: () => {
            const event = new CustomEvent('delayload');
            window.dispatchEvent(event);
        }
    }
};
window.runner              = {
    addNow:     fn => window.runStack.push(fn),
    addDelayed: fn => window.delay(fn),
    addTimeout: (fn, timeout) => window.setTimeout(fn, timeout),
    run:        () => {
        window.runStack = window.runStack ?? [];
        if (window.runStackLocked || window.runStack.length === 0) return;

        window.runStackLocked = true;
        console.log('Running', window.runStack.length, 'functions');
        let fn;
        while (fn = window.runStack.shift()) {
            fn();
        }
        window.runStackLocked = false;
    },
    spool:      () => {
        window.delayStack = window.delayStack ?? [];
        if (window.delayStackLocked || window.delayStack.length === 0) return;

        window.delayStackLocked = true;

        console.log('Moving', window.delayStack.length, 'delayed functions to run stack');
        let fn;
        while (fn = window.delayStack.shift()) {
            runner.addNow(fn);
        }

        window.delayStackLocked = false;
    },
    init:       () => {

        Object.keys(schedule.timeouts).forEach(timeout => runner.addTimeout(schedule.timeouts[timeout], timeout));
        schedule.now.forEach(runner.addNow);
        schedule.delayed.forEach(runner.addDelayed);

        runner.run();
        runner.spool();

        window.setInterval(runner.run, 100);
        window.setInterval(runner.spool, 1000);

    }
};

domReady(() => {
    [
        {
            tag:         'script',
            src:         '/static/fontawesome/54c4a28599.js',
            crossOrigin: 'anonymous',
            async:       true
        }
        /*{
         tag:   'link',
         rel:   'stylesheet',
         href:  'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700;800;900&display=swap',
         async: true
         }*/
    ].forEach(font => runner.addDelayed(() => window.loadTag(font)));

    window.delayStackUnlock = event => {
        console.log('Delayed stack unlocked by', event.type);
        window.unlockEvent      = event.type;
        window.delayStackLocked = false;

        // Destroy myself
        window.delayStackUnlock = t => console.log('NOOP', t.type, ' < ', event.type);
    };

    [
        'click',
        'mousemove',
        'wheel',
        'scroll',
        'touchstart',
        'keypress',
        'delayload'
    ].forEach(type => {
        window.addEventListener(type, event => window.delayStackUnlock(event), {once: true});
    });

    runner.init();

    const debug = document.getElementById('debug-local');
    if (debug) {
        debug.style.display = 'block';
        const updateDebug   = () => debug.innerHTML = Object
            .entries(
                {
                    'now':   window.runStack.length,
                    'delay': window.delayStack.length,
                    'event': !window.delayStackLocked && window.unlockEvent ? window.unlockEvent : '-',
                    'hits':  window.hits
                })
            .map(([k, v]) => `${k}: ${v}`)
            .join(' | ');
        window.setInterval(updateDebug, 100);
    }

});
