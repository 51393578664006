class OnScroll {
    callbacks   = {};
    endTimeout;
    isScrolling = false;
    paused      = true;

    constructor() {
        this.init();
    }

    init() {
        this.paused = false;

        this.reset();

        window.addEventListener('scroll', this.onScroll.bind(this));

        window.setInterval(this.scrolling.bind(this), 600);
    }

    onScroll() {
        if (!this.isScrolling) {
            this.scrollstart();
        }

        window.clearTimeout(this.endTimeout);

        this.endTimeout = window.setTimeout(this.scrollend.bind(this), 100);
    }

    scrollstart() {
        this.isScrolling = true;

        this.fire('scrollstart', this.getData());
    }

    scrolling() {
        if (this.isScrolling) {
            this.fire('scroll', this.getData());
        }
    }

    scrollend() {
        this.isScrolling = false;
        this.endX        = window.scrollX;
        this.endY        = window.scrollY;
        this.deltaX      = this.endX - this.startX;
        this.deltaY      = this.endY - this.startY;
        this.delta       = Math.pow((Math.pow(Math.abs(this.deltaX), 2) + Math.pow(Math.abs(this.deltaY), 2)), 0.5);

        this.fire('scrollend', this.getData());

        window.setTimeout(() => {
            this.fire('scroll', this.getData());
            this.reset();
        }, 600);
    }

    subscribe(event, callback) {
        (this.callbacks[event] = this.callbacks[event] || []).push(callback);
    }

    fire(event, data) {
        if (this.paused) {
            // return;
        }

        const callbacks = this.callbacks[event] || [];

        callbacks.forEach(callback => {
            const fn = typeof callback === 'function' ? callback : (data) => new callback(data);
            fn(data);
        });
    }

    getData() {
        const {startX, startY, endX, endY, deltaX, deltaY, delta} = this;
        return {startX, startY, endX, endY, deltaX, deltaY, delta};
    }

    reset() {
        this.startX = window.scrollX;
        this.startY = window.scrollY;
        this.endX   = null;
        this.endY   = null;
        this.deltaX = null;
        this.deltaY = null;
        this.delta  = null;
    }
}

export default OnScroll;
