export default class Ellipse {
    constructor(options) {
        if (!options.parent || !options.selector) {
            throw new Error('Parent and selector are required');
        }

        this.options     = options;
        this.parent      = options.parent;
        this.selector    = options.selector;
        this.id          = options.id || options.selector.id || this.generateId();
        this.strokeColor = options.strokeColor ?? 'blue';
        this.lineWidth   = options.lineWidth ?? 1;
        this.padding     = options.padding ?? {x: 40, y: 40};
        this.offset      = options.offset ?? {x: 0, y: 0};
        this.transform   = this.getOption('transform', {x: 0, y: 0, width: 0, height: 0});
        this.minAngle    = options.minAngle ?? 0;
        this.maxAngle    = options.maxAngle ?? Math.PI * 4;
        this.rotate      = options.rotate ?? 0;
        this.maxPoints   = options.maxPoints ?? 100;
        this.direction   = options.direction ?? 1;
        this.powers      = options.powers ?? {x: 4, y: 4};

        this.points       = [];
        this.curveStep    = 0.01;
        this.drawInterval = 50;

        this.init();
        this.calculatePoints();
    }

    generateId() {
        this.selector.id = 'selector_' + this.selector.outerHTML.hashCode();
        return this.selector.id;
    }

    getOption(key, defaultValue, ...args) {
        const value = this.options[key] ?? defaultValue;
        return typeof value === 'function' ? value(args) : value;
    }

    init() {
        // Delete old canvas if it exists
        const id = 'canvas_' + this.id;
        document.getElementById(id)?.remove();

        // calculate position and dimensions of the ellipse from the selector
        this.cvs                = document.createElement('canvas');
        this.cvs.id             = id;
        this.cvs.width          = this.offset.width + 2 * this.padding.x - this.transform.x + (this.transform.width ?? 0);
        this.cvs.height         = this.offset.height + 2 * this.padding.y - this.transform.y + (this.transform.height ?? 0);
        this.cvs.style.position = 'fixed';
        this.cvs.style.zIndex   = '99999991';
        this.cvs.style.left     = (this.offset.left - this.padding.x + this.transform.x) + 'px';
        this.cvs.style.top      = (this.offset.top - this.padding.y + this.transform.y) + 'px';
        this.width              = (this.offset.width + this.padding.x - this.transform.x + (this.transform.width ?? 0)) / 2;
        this.height             = (this.offset.height + this.padding.y - this.transform.y + (this.transform.height ?? 0)) / 2;
        this.ctx                = this.cvs.getContext('2d');
        this.ctx.strokeStyle    = this.strokeColor;
        this.ctx.lineWidth      = this.lineWidth;
        this.cvs.classList.add('pointer-events-none');
        this.parent.appendChild(this.cvs);
    }

    calculatePoints() {
        // Calculate the points of the ellipse
        const steps = this.maxPoints;
        let cur     = {};
        let angle   = 0;

        for (let i = 0; i <= steps; i++) {

            angle = this.minAngle + 2 * Math.PI / steps * i * this.direction;

            if (angle > this.maxAngle) {
                console.log('skipping', angle, this.minAngle, this.maxAngle);
                continue;
            }

            angle += this.rotate;

            let aberation = this.getOption('aberation', {x: 0, y: 0}, angle);

            cur = {
                x: this.width * this.getPos(angle, Math.cos, this.powers.x) +      // calculate the x position
                       (aberation.x * i / steps) +                                     // make the ellipse "unperfect"
                       this.width + this.padding.x / 2,                                //center the ellipse horizontally
                y: this.height * this.getPos(angle, Math.sin, this.powers.y)
                       + (aberation.y * i / steps)                                     // make the ellipse "unperfect"
                       + this.height + this.padding.y / 2                             // center the ellipse vertically
            };

            this.points.push(cur);
        }
    }

    getPos(angle, fn, pow) {
        return Math.sign(fn(angle)) * Math.pow(Math.abs(fn(angle)), 2 / pow);
    }

    draw(args) {
        const ctx = this.ctx, step = this.curveStep;
        ctx.beginPath();

        let {t, prev, iv, callback} = args ?? {};

        t ??= 0;
        iv ??= this.drawInterval;

        if (prev) {
            ctx.moveTo(prev.x, prev.y);
        }

        let cur = this.getCurvePoint(t, this.points);
        prev    = cur;
        ctx.lineTo(cur.x, cur.y);
        ctx.stroke();

        t += step;
        if (t < 1 + step) {
            window.setTimeout(() => this.draw({t, prev, iv, callback}), iv);
            iv = Math.min(10, iv * .7);
        } else if (callback) {
            callback();
        }
    }

    drawSkel() {
        const ctx       = this.ctx, points = this.points;
        ctx.strokeStyle = 'red';
        ctx.beginPath();
        points.forEach(function(p, i) {
            if (i === 0) {
                ctx.moveTo(p.x, p.y);
            } else {
                ctx.lineTo(p.x, p.y);
            }
        });
        ctx.stroke();
    }

    drawPoints() {
        const ctx       = this.ctx, points = this.points;
        const TAU       = 2 * Math.PI;
        ctx.strokeStyle = 'black';
        points.forEach(function(p) {
            ctx.beginPath();
            ctx.arc(p.x, p.y, 5, 0, TAU);
            ctx.stroke();
        });
    };

    getCurvePoint(t, points) {
        if (points.length === 1) return points[0];
        let newpoints = [];
        for (let i = 0, j = 1; j < points.length; i++, j++) {
            newpoints[i] = this.lerp2d(t, points[i], points[j]);
        }
        return this.getCurvePoint(t, newpoints);
    }

    lerp(ratio, start, end) {
        return ratio * start + (1 - ratio) * end;
    }

    lerp2d(ratio, start, end) {
        return {
            x: this.lerp(ratio, start.x, end.x),
            y: this.lerp(ratio, start.y, end.y)
        };
    }
}
