// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

import {install} from '@github/hotkey';
import OnScroll from '../on-scroll';
import Mark from 'mark.js';
import {appConfig} from '../config';
import Ellipse from '../ellipse';

export const root         = document.querySelector(':root'),
             header       = _q('header'),
             topNav       = _q('#top-nav'),
             main         = _q('main'),
             searchButton = _q('.show-search'),
             toc          = _q('.content-toc.horizontal');

export const adjustGoTopOpacity = () => {
    const stickyTools = _q('#sticky-tools');
    if (window.scrollY * 3 > window.innerHeight) {
        _q('#go-top').classList.remove('hidden');
    } else {
        _q('#go-top').classList.add('hidden');
    }
    stickyTools.style.setProperty('--tw-translate-y', '-50%');
    stickyTools.style.setProperty('--scroll-progress', '100%');
};

/**
 * Build the table of contents from the content headers (h2, h3.toc)
 */
export const buildToc = () => {
    const _content      = _q('.toc-source .main-content');
    const _toc          = [..._qa('.content-toc')].filter(el => getComputedStyle(el).display !== 'none')[0];
    const verticalToc   = _toc && _toc.classList.contains('vertical');
    const horizontalToc = _toc && !verticalToc;

    let sections = [];

    if (_toc) {

        // Must empty on resize
        _toc.innerHTML = '';

        if (_content) {
            const acordeonize = (heading) => {
                if (heading.classList.contains('has-panel')) {
                    return;
                }

                const addEvents = (heading) => {
                    heading.addEventListener('expand', (e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        heading.parentElement.querySelectorAll('.has-panel').forEach(el => el === heading || el.classList.add('collapsed'));
                        heading.classList.remove('collapsed');
                        location.href = '#' + heading.id;
                    });

                    heading.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        heading.parentElement.querySelectorAll('.has-panel').forEach(el => el === heading || el.classList.add('collapsed'));
                        heading.classList.toggle('collapsed');
                        location.href = '#' + heading.id;
                    });
                };
                const contents  = heading.nextUntil('h2, h3.toc, hr, .no-collapse');
                if (contents.length > 0) {
                    // Create a panel and move the contents inside
                    const panel = document.createElement('div');
                    panel.classList.add('panel');
                    contents.forEach(el => panel.appendChild(el.parentElement.removeChild(el)));
                    // Insert the panel after the heading
                    heading.after(panel);
                    heading.innerHTML = '<span>' + heading.innerHTML + '</span>';
                    heading.classList.add('has-panel', 'relative', 'collapsed', 'cursor-pointer');
                    addEvents(heading);
                }
            };

            sections = [..._qa(_content, 'h2, h3.toc')].filter(el => getComputedStyle(el).display !== 'none' && el.innerText.trim() !== '');

            if (sections.length > 0) {

                sections.forEach((title) => {

                    let titleText = ((title.querySelector('strong') && title.querySelector('strong').innerText.trim()) || title.title || title.innerText);
                    // noinspection RegExpRedundantEscape
                    titleText     = titleText.replace(/(\[[><][0-9]+\])/g, '').trim();

                    title.id = [
                        'toc',
                        // i,
                        window.slugger(titleText.toLowerCase().replace(/[^âăîșța-z0-9_\-]/gi, '_')).substring(0, 40)
                    ].join('-');
                    title.classList.add('scroll-spy');
                    title.dataset.scrollTarget = '#link_' + title.id;

                    let link       = document.createElement('a');
                    link.innerText = titleText/*.limit(40, true)*/.capitalize();
                    link.id        = title.dataset.scrollTarget.replace('#', '');
                    link.href      = title.childNodes[0].href || '#' + title.id;
                    link.title     = title.innerText;

                    if (title.dataset.tocClass) {
                        link.classList.add(title.dataset.tocClass);
                    }
                    link.classList.add('from-' + title.tagName.toLowerCase());

                    _toc.appendChild(link);

                    // Ignore H3 and CTA titles
                    if (title.tagName.toLowerCase() === 'h2' && title.dataset.tocClass !== 'toc-cta') {

                        const shouldAcordeonize = (verticalToc && appConfig.toc.acordeonizeVertical) || (horizontalToc && appConfig.toc.acordeonizeHorizontal) &&
                            !title.classList.contains('no-accordion');

                        if (shouldAcordeonize) {
                            acordeonize(title);

                            if ((verticalToc && appConfig.toc.verticalLinksExpandPanel) || (horizontalToc && appConfig.toc.horizontalLinksExpandPanel)) {
                                link.addEventListener('click', () => {
                                    title.dispatchEvent(new Event('expand'));
                                });
                            }
                        }
                    }
                });

                // expand the first section
                if (_content.querySelector('.has-panel')) {
                    _content.querySelector('.has-panel').classList.remove('collapsed');
                }

                _toc.classList.remove('hidden');
            }
        }

        if (sections.length === 0) {
            _toc.classList.add('!hidden');
        }
    }
};

/**
 * Collapse the header when scrolling down
 * @param data
 */
export const collapseHeader = (data) => {
    if (!header) {
        return;
    }

    if (data.endY > (window.innerHeight / 3) + header.clientHeight) {
        root.style.setProperty('--header-mini-offset', appConfig.header.miniOffset + 'px');
        header.classList.add('mini');
    } else {
        root.style.setProperty('--header-mini-offset', 0);
        header.classList.remove('mini');
    }
};

/**
 * Spy the scrollTop and highlight the current section in the TOC
 */
export const scrollSpy = () => {
    const candidates = () => Array.from(_qa('.scroll-spy')).map(el => {
        return {
            top: el.getBoundingClientRect().top + document.documentElement.scrollTop, el: el
        };
    }).filter(el => el);

    const winners = (candidates) => {
        let winner = null, winnerTarget = null;

        for (let {top, el} of candidates) {
            if (top - 100 < window.scrollY + window.innerHeight / 2) {
                winner       = el;
                winnerTarget = _q(el.dataset.scrollTarget);
            }
        }

        return {winner, winnerTarget};
    };

    const scroll = () => {
        const {winnerTarget} = winners(candidates());

        if (!winnerTarget) {
            return;
        }

        // Don't scroll if the target is already visible
        if (window.lastScrollSpy === winnerTarget) {
            return;
        }

        window.lastScrollSpy = winnerTarget;
        winnerTarget.scrollIntoViewSmoothly();
    };

    const spy = () => {
        const all  = candidates(), {winner, winnerTarget} = winners(all);
        let target = null;

        all.filter(({el}) => !winner || el !== winner).forEach(({el}) => {
            el.classList.remove('active');
            target = _q(el.dataset.scrollTarget);
            if (target) {
                target.classList.remove('current');
            }
        });

        if (winner && winnerTarget) {
            winnerTarget.classList.add('current');
            winner.classList.add('active');

            window.scrollSpyInterval && window.clearInterval(window.scrollSpyInterval);
            window.scrollSpyInterval = window.setTimeout(scroll, 100);
        }
    };

    return {
        spy
    };
};

export const fixTables = () => {
    _qa('.page-wrapper table').forEach(table => {
        const div = document.createElement('div');
        div.classList.add('table-responsive');
        div.innerHTML = table.outerHTML;
        table.parentElement.replaceChild(div, table);
    });
};

/**
 * Replace text in a DOM element without destroying the DOM
 * @param element
 * @param pattern
 * @param callback
 */
export const replaceInText = (element, pattern, callback) => {
    let children = Array.from(element.childNodes), node;
    for (let i in children) {
        node = children[i];
        switch (node.nodeType) {
            case Node.ELEMENT_NODE:
                replaceInText(node, pattern, callback);
                break;
            case Node.DOCUMENT_NODE:
                replaceInText(node, pattern, callback);
                break;
            case Node.TEXT_NODE:
                let replaced  = node.textContent;
                const matches = replaced.match(pattern);
                if (matches) {
                    callback(node, matches);
                }
                break;
        }
    }
};

/**
 * Scroll the top navigation to the end and back to the current link
 */
export const scrollTopNav = () => {

    const links = Array.from(_qa('#top-nav .sections a'));
    if (links.length) {
        (links.slice(-1)[0]).scrollIntoViewSmoothly();

        const currentLink = _q('#top-nav a.parent, #top-nav a.current');
        if (currentLink) {
            window.setTimeout(() => currentLink.scrollIntoViewSmoothly(), 1000);
        }
    }
};

/**
 * Set the current links in the navigation based on the current URL
 */
export const setCurrentLinks = () => {
    const here = new URL(location.href);
    document.querySelectorAll('a, nav[data-section]').forEach(link => {
        // noinspection JSUnresolvedVariable
        const href = link.dataset.section || (link.attributes.href ? link.attributes.href.value : '');

        if (href && href.substring(0, 8) === 'https://' && window.location.hostname === new URL(href).hostname) {
            const url   = new URL(href);
            let classes = [];

            if (here.pathname === url.pathname) {
                if (url.hash && here.hash === url.hash) {
                    // link.classList.add('active');
                } else {

                    // Do NOT add .current to pagination links
                    if (!url.searchParams.has('page')) {
                        classes.push('current');
                    } else {
                        // UNLESS
                        // they have the same page number OR
                        // they are the first page
                        if ((here.searchParams.has('page') && url.searchParams.get('page') === here.searchParams.get('page')) ||
                            (!here.searchParams.has('page') && url.searchParams.get('page') === '1')) {
                            classes.push('current');
                        }
                        // OTHERWISE, add .sibling
                        else {
                            classes.push('sibling');
                        }
                    }
                }
            } else {
                // Paths are different, but the location includes the current path
                if (here.pathname.indexOf(url.pathname) === 0) {
                    classes.push('parent');
                }
            }

            if (classes.length > 0) {
                link.classList.add(...classes);
            }
        }
    });
};

export const setupAnalyticsGA = async (ga_id, hash, cookieOptions) => {
    gtag('js', new Date());
    gtag('config', ga_id, [
        'anonymize_ip',
        true
    ]);

    console.log('GTAG: update consent', cookieOptions);
    gtag('consent', 'update', {
        ad_user_data: cookieOptions.marketing ? 'granted' : 'denied',
        ad_personalization: cookieOptions.marketing ? 'granted' : 'denied',
        ad_storage: cookieOptions.marketing ? 'granted' : 'denied',
        analytics_storage: cookieOptions.performance ? 'granted' : 'denied',
    });

    gtag('config', 'client_id', hash);

    loadTag({
                tag: 'script',
                src: 'https://www.googletagmanager.com/gtag/js?id=' + ga_id
            }, (script) => console.log('Analytics tag inserted', script));
};

export const setupAnalyticsGTM = (gtm_id) => {
    loadTag({
                src: 'https://www.googletagmanager.com/gtag/js?id=' + gtm_id, async: true
            }, (script) => console.log('GTM tag inserted', script));
};

export const setupExchangePage = () => {

    // noinspection JSUnresolvedVariable
    if (!_q('#currency-table') || typeof window.rates === 'undefined') {
        return;
    }

    // noinspection JSUnresolvedVariable
    const rates = window.rates ?? [];

    //////
    /// Tabs
    //////
    const tabs      = document.querySelectorAll('#currency-table .tabs a');
    const selectTab = tab => {
        const container = tab.closest('.tab-container');

        container.querySelectorAll('.tabs a').forEach(tab => tab.classList.remove('active'));
        container.querySelectorAll('.tab-target').forEach(content => content.classList.add('hidden'));

        _q(tab.getAttribute('href')).classList.remove('hidden');
        tab.classList.add('active');
    };
    tabs.forEach(tab => {
        tab.addEventListener('click', e => {
            e.preventDefault();
            selectTab(e.target);
        });
    });
    selectTab(_q('#currency-table .tabs a:first-child'));

    //////
    /// Calculator
    //////
    const calculator = window.calculator = (operation, where, amount, from, to) => {
        if (from === to) {
            return parseFloat(amount);
        }

        const crossSymbol      = 'RON';
        let crossOperation     = operation === 'sell' ? 'buy' : 'sell';
        let crossRateOperation = [
            where,
            crossOperation
        ].join('_');
        let rateOperation      = [
            where,
            operation
        ].join('_');

        if (where === 'reference') {
            // noinspection JSUnusedAssignment
            operation          = crossOperation = '';
            crossRateOperation = rateOperation = 'reference';
        }

        const crossRate = to === crossSymbol ? 1 : rates.filter(rate => rate.symbol === to)[0][rateOperation];
        const rate      = from === crossSymbol ? 1 : rates.filter(rate => rate.symbol === from)[0][[crossRateOperation].join('_')];

        return (rate / crossRate) * amount;
    };
    const calculate  = () => {
        const form      = _q('.calculator-container form');
        const to_amount = _q(form, 'input[name="to_amount"]');
        const operation = _q(form, 'input[name="operation"]').value;
        const where     = _q(form, 'select[name="where"]').value;
        const from      = _q(form, 'select[name="from"]').value;
        const amount    = _q(form, 'input[name="from_amount"]').value;
        const to        = _q(form, 'select[name="to"]').value;
        to_amount.value = calculator(operation, where, amount, from, to).toFixed(2);
        form.classList.remove('buy', 'sell');
        form.classList.add(operation);
    };
    const calcTab    = (tab) => {
        const container = _q('.calculator-container');
        const form      = container.querySelector('form');
        container.querySelectorAll('.tabs a').forEach(tab => tab.classList.remove('active'));
        tab.classList.add('active');
        const opField = _q(form, 'input[name="operation"]');

        // noinspection JSUnresolvedVariable
        opField.value = tab.attributes.href.value.replace('#', '');
        calculate();
    };

    _qa('.calculator-container .tabs a').forEach(tab => tab.addEventListener('click', e => {
        e.preventDefault();
        calcTab(e.target);
    }));
    _qa('.calculator-container input, .calculator-container select').forEach(input => input.addEventListener('input', calculate));
    _qa('.calculator-container .switch').forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();
            const form      = _q('.calculator-container form');
            const from      = _q(form, 'select[name="from"]');
            const to        = _q(form, 'select[name="to"]');
            const fromValue = from.value;
            from.value      = to.value;
            to.value        = fromValue;
            calculate();
        });
    });
    calcTab(_q('.calculator-container .tabs a:first-child'));
};

/**
 * Fix forms
 */
export const setupForms = () => {
    if (_qa('.form-errors').length) {
        _qa('.form-errors')[0].scrollToSmoothly();
    }
};

export const setupFormLinks = () => {
    _qa('.form-link').forEach(ctaLink => {
        if (ctaLink && ctaLink.dataset.target) {
            const forms = Array.from(_qa(ctaLink.dataset.target)).filter(f => getComputedStyle(f).display !== 'none');
            if (forms.length) {
                const form           = forms[0];
                ctaLink.onclick      = e => {
                    e.preventDefault();
                    form.scrollToSmoothly(() => {
                        _q(form, 'input[type^=text]:first-of-type').focus();
                    });
                };
                const displayCtaLink = () => form.isInViewport() ? ctaLink.classList.add('hidden') : ctaLink.classList.remove('hidden');
                displayCtaLink();
                window.addEventListener('scroll', displayCtaLink);
            }
        }
    });
};

/**
 * Setup footnotes
 * Convert every [>x] and [<x] to a link to and from the footnote with id x
 */
export const setupFootnotes = () => {
    const replaceCallback = (node, matches) => {

        if (!node.parentElement) {
            console.error('No parent element for node', node);
            return;
        }

        let content = node.textContent;
        let refs    = [];

        matches.forEach(match => {
            const id          = match.match(/[0-9]+/g)[0];
            const direct      = match.includes('>');
            const refClass    = (direct ? 'footnote-ref' : 'footnote');
            const targetClass = (direct ? 'footnote' : 'footnote-ref');
            const prefix      = direct ? '' : '';
            const replacement = `<a class="${refClass}" id="${refClass}${id}" href="#${targetClass}${id}"><code>${prefix}${id}</code></a>`;
            content           = content.replace(match, replacement);

            node.parentElement.classList.add(refClass + '-container');
            refs.push({
                          source: '#' + refClass + id,
                          target: '#' + targetClass + id
                      });
        });

        // Because node.textContent contains unescaped HTML entities, we need to create a new element and get the innerHTML from it to get the correct HTML
        const escapeElement     = document.createElement('div');
        escapeElement.innerHTML = node.textContent;

        // We replace the rendered|escaped node.textContent with the generated HTML
        node.parentElement.innerHTML = node.parentElement.innerHTML.replace(escapeElement.innerHTML, content);

        refs.forEach(pair => document.querySelector(pair.source).addEventListener('click', () => {
            const target = document.querySelector(pair.target);
            if (target) {
                const title = target.closest('.panel').previousElementSibling;
                if (title && title.classList.contains('collapsed')) {
                    title.classList.remove('collapsed');
                }
            }
        }));
    };

    if (main) {
        // noinspection RegExpRedundantEscape
        replaceInText(main, /(\[[><][0-9]+\])/g, replaceCallback);
    }
};

export const setupHighlighting = () => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('hl')) {
        const instance = new Mark(document.querySelector('#main'));
        url.searchParams.get('hl').split(' ').forEach(term => instance.mark(term));
    }
};

/**
 * Enable hotkeys
 * @deprecated
 */

export const setupHotkeys = () => {
    for (const el of document.querySelectorAll('[data-hotkey]')) {
        if (el.dataset.hotkey !== '') {
            install(el);
        }
    }
};

export const setupModalTriggers = () => {
    document.querySelectorAll('[data-modal]').forEach(function(el) {
        el.addEventListener('click', function(e) {
            e.preventDefault();

            // If [modal-target] is set, use the content of the element with that selector
            const html   = this.dataset.modalTarget ? document.querySelector(this.dataset.modalTarget).innerHTML : this.outerHTML; // Otherwise, use the content of the element that triggered the modal
            const el     = document.createElement('div');
            el.innerHTML = html;

            // If the element has an image as first child, use that as the modal image
            // noinspection JSUnresolvedVariable
            window.modal().content(el.firstChild.tagName.toLowerCase() === 'img' ? `<img src="${el.firstChild.src}" alt="${el.firstChild.alt}">` : html);

            window.modal().open();
        });
    });
};

export const setupOnlineDecoration = () => {
    if (document.body.classList.contains('template-oriunde')) {
        return;
    }

    if (window.fromAd) {
        return;
    }

    const storageKey  = 'hundredPercentPrefsDecember2023';
    let attempts      = 0, last = new Date();
    const storeVisits = (date, attempts) => {
        localStorage.setItem(storageKey, JSON.stringify(
            {
                date:     date.toISOString(),
                attempts: attempts
            }));
    };

    try {
        const stored = localStorage.getItem(storageKey);
        attempts     = stored ? parseInt(JSON.parse(stored)?.attempts, 10) : 0;
        last         = new Date(JSON.parse(stored)?.date);
        last         = !isNaN(last) ? last : new Date();
    } catch (e) {
        console.error(e);
    }

    // Reset attempts after 7 days
    if ((new Date - last) / 1000 > 60 * 60 * 24 * 7) {
        attempts = 0;
        last     = new Date();
    }

    attempts++;

    if (attempts > 3) {
        return;
    }

    storeVisits(last, attempts);

    const selector       = document.querySelector('div#main-nav nav.links.ctas'),
          parent         = document.querySelector('div#main-nav'),
          oriunde        = document.querySelector('div#main-nav nav.links.ctas .oriunde'),
          button         = document.querySelector('div#main-nav nav.links.ctas button'),
          percentButton  = document.querySelector('div#main-nav nav.links.ctas .depozit'),
          percentClasses = ['start-animation'],
          cancasClasses  = [
              'opacity-0',
              'transition-all',
              'duration-300'
          ],
          mainClasses    = [
              /*'opacity-50',
               'saturate-0',
               'transition-all',
               'duration-300'*/
          ];

    if (selector && parent) {
        const baseOptions            = {
                  parent,
                  selector,
                  id:          'large',
                  offset:      {
                      left:   selector.offsetLeft,
                      top:    selector.offsetTop,
                      width:  selector.offsetWidth,
                      height: selector.offsetHeight
                  },
                  strokeColor: '#f05000',
                  lineWidth:   2,
                  transform:   () => ({x: oriunde.offsetLeft - selector.offsetLeft + 16, y: 16, width: -button.offsetWidth - 8}),
                  padding:     {x: 60, y: 35},
                  maxPoints:   100,
                  aberation:   (angle) => {
                      return {x: 3 * Math.cos(angle), y: -20};
                  },
                  zIndex:      9999998,
                  rotate:      Math.PI,
                  powers:      {x: 6, y: 3}
              },
              el                     = new Ellipse(baseOptions),
              cvs                    = el.cvs,
              ctx                    = cvs.getContext('2d'),
              removeOnlineDecoration = () => {
                  percentButton.classList.remove(...percentClasses);
                  main.classList.remove(...mainClasses);
                  cvs.classList.add(...cancasClasses);
                  window.setTimeout(() => cvs.remove(), 300);
              },
              addFinalTouches        = () => {
                  const start   = {x: cvs.clientWidth / 2 - 15, y: cvs.clientHeight - 15};
                  ctx.lineWidth = 2;
                  const end     = start;

                  // Arrow body
                  ctx.beginPath();
                  ctx.moveTo(end.x, end.y);
                  ctx.lineTo(end.x - 5, end.y - 13);
                  ctx.stroke();

                  // Arrow head
                  ctx.moveTo(end.x, end.y);
                  ctx.beginPath();
                  ctx.ellipse(end.x, end.y, 5, 10, Math.PI * .7, 0, Math.PI * 1.6, true);
                  ctx.stroke();

                  // Arrow head
                  ctx.moveTo(end.x - 10, end.y - 10);
                  ctx.beginPath();
                  ctx.ellipse(end.x, end.y, 10, 5, Math.PI * .3, 0, Math.PI * 1.6, true);
                  ctx.stroke();

                  percentButton.classList.add(...percentClasses);

                  window.setTimeout(removeOnlineDecoration, 4000);

                  [
                      'resize',
                      'click',
                      'wheel'
                  ].forEach(event => window.addEventListener(event, removeOnlineDecoration));
              };

        main.classList.add(...mainClasses);
        el.draw({callback: addFinalTouches});
    }
};

export const setupScrollEvents = () => {
    scrollSpy().spy();

    const onScroll = new OnScroll;
    onScroll.subscribe('scrollend', (data) => {
        collapseHeader(data);
    });
    onScroll.subscribe('scroll', () => {
        scrollSpy().spy();
    });
};

/**
 * Make the search button toggle the search box
 */
export const setupSearchButton = () => {
    if (searchButton && _q('.search-wrapper')) {
        searchButton.onclick = e => {
            e.preventDefault();
            _q('.search-wrapper').classList.toggle('hidden');
            _q('.search-wrapper input[name=q]').focus();
        };
    }
};

/**
 * Setup table downloads
 */
// noinspection JSUnusedGlobalSymbols
export const setupTableDowloads = () => {
    const findTables = () => {
        const tables = _qa('table.table[summary]');
        tables.forEach(table => {
            const name     = table.getAttribute('summary');
            const filename = slugger(name, {limit: 30}) + '.xlsx';

            const btn     = document.createElement('a');
            btn.innerHTML = '<i class="fa-duotone fa-download"></i> Excel';
            btn.setAttribute('download', name);
            btn.addEventListener('click', () => {
                // noinspection JSUnresolvedReference
                window.ExcellentExport.convert({anchor: btn, filename: filename, format: 'xlsx'}, [
                    {
                        name: name.substring(0, 30), from: {table}
                    }
                ]);
            });

            table.parentElement.insertBefore(btn, table.nextSibling);
        });

        return tables;
    };

    const injectScript = (src, onload) => {
        const script = document.createElement('script');
        script.src   = src;
        document.head.appendChild(script);
        script.onload = onload;
    };

    window.setTimeout(() => {
        const tables = findTables();

        if (tables.length) {
            injectScript('https://cdn.jsdelivr.net/npm/excellentexport/dist/excellentexport.min.js', () => window.ExcellentExport = ExcellentExport);
        }
    }, 15000);
};
/**
 * Setup tabs
 */
export const setupTabs          = () => {
    window.activateTab = (options) => {

        const tab    = options.tab;
        const btn    = options.btn ?? _q(`[data-tab="${tab}"]`);
        const target = _q(tab);

        if (target) {
            btn.parentElement.siblings()
               .map(sibling => sibling.querySelector('[data-tab]'))
               .filter(a => a)
               .forEach(a => {
                   a.classList.remove('active');
                   _q(a.dataset.tab).classList.remove('active');
               });

            btn.classList.add('active');
            target.classList.add('active');

            if (options.scrollTo) {
                btn.parentElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    _qa(':not(.no-tabs) [data-tab]').forEach(btn => {
        const clickTabBtn = (btn, updateHash) => {
            const tab = btn.dataset.tab;
            activateTab({tab, btn});
            if (updateHash ?? true) {
                location.hash = 'tab:' + tab.replace('#', '');
            }
        };

        btn.addEventListener('click', e => {
            e.preventDefault();
            clickTabBtn(btn);
        });

        if (btn.classList.contains('active')) {
            clickTabBtn(btn, false);
        }
    });
};

/**
 * Calculate the visible header height
 * @returns {number}
 */
window.visibleHeaderHeight = () => header ? header.clientHeight + new DOMMatrixReadOnly(window.getComputedStyle(header).getPropertyValue('transform')).m42 : 0;
